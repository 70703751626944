import { employeeinfo } from "../commonDynmicForms/employee_info";
import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";


export default {
  fillable_by : FillableBy.Both,
  title:
    "Perioperative Blood Management Clinical Competency",
  sections: [
    // {...employeeinfo},
    {
      title: "Competency Statement: During orientation, and on a annual basis thereafter, the employee's clinical competencies shall be assessed.",
      type: "table",
      heads: constants.tableHeads,
      rows: [
        "Demonstrates process to set-up, start,and shut- down equipment, including appropriate use of checklists",
        "Operates equipment safely, appropriately, and according to patient’s needs",
        "Demonstrates the addition of anticoagulant type and amount required for the anticoagulant drip bag.",
        "Cleans/disinfects and stores equipment as per manufacturer's recommendations and facility policy/procedures",
        "Accurately documents: Set-up Preventative and routine maintenance Cleaning/disinfection Equipment malfunctions/ failure",
        "Responds to alarms in a timely manner",
        "Troubleshoots cell saver and related equipment alarms in an organized and timely manner",
        "Initiates appropriate and timely interventions based upon alarm data and equipment assessment.",
        "Demonstrates procedures for routine replacement of cell saver, PRP centrifuge and related equipment as per facility policy/procedure",
        "Identifies process to document equipment failure",
        "Identifies process to have equipment repaired",
        "Able to calculate and understand EBL",
        "Able to discuss indications / contraindications / complications with physician.",
        "Antipates periods of high blood loss and immediately responds to process the collected blood.",
        "Labels transfer bag(s) and syringes with patient’s name, MR number, estimated volume, time of collection and expiration date/time."
      ].map((item) => ([{ text: item, type: 'text' }, ...table_fields]))
    },
    {...evaluationCheckList},
    {
      title: "Signatures",
      type: "signature",
      fields: getSignatures(FillableBy.Both)
    },
  ]
}